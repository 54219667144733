exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-category-loadmore-js": () => import("./../../../src/templates/category/loadmore.js" /* webpackChunkName: "component---src-templates-category-loadmore-js" */),
  "component---src-templates-types-page-js": () => import("./../../../src/templates/types/page.js" /* webpackChunkName: "component---src-templates-types-page-js" */),
  "component---src-templates-types-post-js": () => import("./../../../src/templates/types/post.js" /* webpackChunkName: "component---src-templates-types-post-js" */),
  "component---src-templates-types-tag-js": () => import("./../../../src/templates/types/tag.js" /* webpackChunkName: "component---src-templates-types-tag-js" */)
}

